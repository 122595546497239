import * as React from 'react'
import { SP, PC } from '../utils/media-query'
const styles = require('scss/simpletag.module.scss')

interface Tag {
  tagName: string
}

export interface SimpleTagsProps {
  tags: Tag[]
}
const SimpleTags = (props: SimpleTagsProps) => {
  return (
    <div className={styles.tagContainer}>
      {props.tags.map((tag, index) => {
        return (
          <div className={styles.tag} key={`tag-${index}`}>
            {tag.tagName}
          </div>
        )
      })}
    </div>
  )
}
export default SimpleTags
