import * as React from 'react'
import axios from 'axios'
import Header from '../../components/organisms/Header'
import Footer from '../../components/organisms/Footer'
import { SP, PC } from '../../components/utils/media-query'
import TitleWithText from '../../components/atoms/TitleWithText'
import SimpleTags from '../../components/atoms/SimpleTags'
import Button from '../../components/atoms/TheButton'
import HeadingIllust from '../../components/atoms/HeadingIlust'
const styles = require('scss/recruit.module.scss')

export type RecruitAPIData = {
  about: string
  aboutTitle: string
  createdAt: Date
  id: string
  job: string
  jobCondition: string
  jobDescription: string
  jobEn: string
  publishedAt: Date
  revisedAt: Date
}

const RecruitPage: React.FC = () => {
  const [recruitList, setRecruitList] = React.useState([])
  React.useEffect(() => {
    axios({
      url: 'https://nichicoma.microcms.io/api/v1/recruit/',
      method: 'get',
      headers: {
        'X-API-KEY': '10c03845-077d-43fd-acbe-044df6d8fe4f',
      },
    }).then((res) => {
      const result = res.data.contents.map(
        (
          recruitData: RecruitAPIData & { tags: string[] },
        ) => {
          return {
            ...recruitData,
            tags: [{ tagName: recruitData.job }],
          }
        },
      )
      setRecruitList(result)
    })
  }, [])
  const spHeadImage = require('images/sp/recruit_0.png')
  const ocHeadImage = require('images/pc/recruit_0.png')

  const spCard_1_image = require('images/sp/recruit_1.png')
  const pcCard_1_image = require('images/pc/recruit_1.png')
  const spCardWebP = require('images/sp/webp/recruit_1.webp')
  const pcCardWebP = require('images/pc/webp/recruit_1.webp')

  const SPBlurImage = require('images/sp/coming_soon_sp.jpg')
  const PCBlurImage = require('images/pc/coming_soon_pc.jpg')
  const spBlurWebP = require('images/sp/webp/coming_soon_sp.webp')
  const pcBlurWebP = require('images/pc/webp/coming_soon_pc.webp')

  const tags = [
    {
      tagName: 'webエンジニア',
    },
  ]
  const pcHeadTitle = (
    <h2>
      ニチコマは、一緒に働くメンバーを募集しています。
    </h2>
  )
  const pcHeadText = (
    <div>
      最新の技術や言語を用いた開発を通してスキルアップを図りたい、チャレンジ精神豊富な方を募集しています。
    </div>
  )
  const spHeadTitle = (
    <h2>
      ニチコマは、
      <br />
      一緒に働くメンバーを募集しています。
    </h2>
  )
  const spHeadText = (
    <div>
      最新の技術や言語を用いた開発を通してスキルアップを図りたい、チャレンジ精神豊富な方を募集しています。
    </div>
  )

  return (
    <section>
      <Header />
      <div>
        <SP>
          <HeadingIllust
            image={spHeadImage}
            title="採用情報"
            pageName="Recruit"
            top={5}
          />
          <div className={styles.titleWrapper}>
            <TitleWithText
              titleNode={spHeadTitle}
              textNode={spHeadText}
            />
          </div>
        </SP>
        <PC>
          <HeadingIllust
            image={ocHeadImage}
            title="採用情報"
            pageName="Recruit"
            top={1}
          />
          <div className={styles.titleWrapper}>
            <TitleWithText
              titleNode={pcHeadTitle}
              textNode={pcHeadText}
            />
          </div>
        </PC>
      </div>
      <div className={styles.gradationWrapper}>
        <h2 className={styles.title}>募集中の職種</h2>
        {recruitList.map((recruitData) => {
          return (
            <div
              className={styles.recruitCard}
              key={recruitData.id}
            >
              <PC>
                <div className={styles.leftImage}>
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={pcCardWebP}
                    />
                    <img
                      src={pcCard_1_image}
                      alt="recruit"
                    />
                  </picture>
                </div>
              </PC>
              <SP>
                <div className={styles.leftImage}>
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={spCardWebP}
                    />
                    <img
                      src={spCard_1_image}
                      alt="recruit"
                    />
                  </picture>
                </div>
              </SP>
              <div className={styles.rightContent}>
                <div className={styles.tagContainer}>
                  <SimpleTags tags={recruitData.tags} />
                </div>
                <div className={styles.description}>
                  {recruitData.about}
                </div>
                <div className={styles.buttonContainer}>
                  <PC>
                    <Button
                      type="square"
                      size="small"
                      icon
                      to={`/recruit/${recruitData.id}`}
                      elevation="1"
                    >
                      詳細を見る
                    </Button>
                  </PC>
                  <SP>
                    <Button
                      size="medium"
                      icon
                      to={`/recruit/${recruitData.id}`}
                      elevation="3"
                    >
                      詳細を見る
                    </Button>
                  </SP>
                </div>
              </div>
            </div>
          )
        })}
        <div className={styles.blurContainer}>
          <PC>
            <picture>
              <source
                type="image/webp"
                srcSet={pcBlurWebP}
              />
              <img src={PCBlurImage} alt="blur" />
            </picture>
          </PC>
          <SP>
            <picture>
              <source
                type="image/webp"
                srcSet={spBlurWebP}
              />
              <img src={SPBlurImage} alt="blur" />
            </picture>
          </SP>
        </div>
      </div>
      <div className={styles.whiteSpace}></div>
      <Footer />
    </section>
  )
}
export default RecruitPage
